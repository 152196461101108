<template>
   <header class="header cus-mobile-header">
      <!-- navbar for pc -->
      <nav class="navbar">
         <div class="navbar__container">
         <section class="navbar__start">
            <div class="logo navbar__logo">
               <a class="link" href="index.html">
               <img class="img" :src="currentLogo" alt />
               </a>
            </div>
         </section>
         </div>
      </nav>

      <!-- navbar for mobile -->
      <a v-show="isShowBackIcon" class="link header-back__back ltr-type" @click="back()" ref="js_backbtn" style="top:17px;">
         <svg
         class="header-back__svg"
         xmlns="http://www.w3.org/2000/svg"
         width="13"
         height="24"
         viewBox="0 0 13 24"
         fill="#000"
         >
         <path
            d="M19.4,56.761l.38-.38a.646.646,0,0,0,0-.913L9.259,44.95,19.776,34.433a.646.646,0,0,0,0-.913l-.38-.38a.646.646,0,0,0-.913,0L7.129,44.494a.646.646,0,0,0,0,.913L18.483,56.761a.646.646,0,0,0,.913,0Z"
            transform="translate(-6.94 -32.951)"
         />
         </svg>
         <!-- <span class="p-l-15">{{ $t("COMMON.BACK") }}</span> -->
      </a>

      <nav class="cus-navbar-mobile">
         <section class="navbar-mobile__panel">
         <div class="logo navbar-mobile__logo">
            <a class="link" href="index.html">
               <img class="img" :src="currentLogo" alt />
            </a>
         </div>
         </section>
      </nav>
   </header>
</template>

<script>
export default {
   name: "PublicHeader",
   props: {
      msg: String
   },
   data() {
      return {
         isShowBackIcon: true
      }
   },
   methods: {
      formatNumber(number) {
         let numberFormatObject = new Intl.NumberFormat("en-US");
         return numberFormatObject.format(number);
      },
      toTitleCase(str) {
         return str.replace(/\w\S*/g, function(txt) {
         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
         });
      },
      back() {
         const id = localStorage.getItem("transactionId");
         const phoneNumber = localStorage.getItem("phone_number");
         if (this.$route.name == "passport-detail") {
            this.$router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
         } else if (this.$route.name == "order-passport") {
            this.$router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
         } else if (this.$route.name == "order-confirmation") {
            this.$router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
         } else if (this.$route.name == "tour-order6") {
            this.$router.push({ name: 'order-confirmation', params: { transaction_id: id, phone_number: phoneNumber } });
         }
      }
   },
   computed: {},
   created() {
      if (this.$route.name == "order-details") {
         this.isShowBackIcon = false;
      } else {
         this.isShowBackIcon = true;
      }
   },
   mounted() {
      // if (
      //    this.$route.name == "passport-detail" ||
      //    this.$route.name == "order-passport" ||
      //    this.$route.name == "order-confirmation" ||
      //    this.$route.name == "tour-order6"
      // ) {
      //    this.$refs.js_backbtn.classList.remove("hide-item");
      // } else {
      //    this.$refs.js_backbtn.classList.add("hide-item");
      // }
      if (this.$i18n.locale == "en") {
         document.getElementsByTagName("body")[0].classList.remove("rtl-type");
         document.getElementsByTagName("body")[0].classList.add("ltr-type");
      } else {
         document.getElementsByTagName("body")[0].classList.remove("ltr-type");
         document.getElementsByTagName("body")[0].classList.add("rtl-type");
      }
   }
};
</script>
<style lang="scss">
   .navbar-mobile__panel {
      justify-content: center;
   }
   .cus-navbar-mobile {
      display: none;
   }
   .cus-mobile-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 61px;
   }

   @media screen and (max-width: 1365px) {
      .cus-navbar-mobile {
         display: block;
         .navbar-mobile__logo {
            height: auto;
            width: 100px;
            .link {
               display: flex;
               img {
                  object-fit: contain;
               }
            }
         }
      }
   }
</style>
