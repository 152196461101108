<template>
  <div class="page app">
    <div>
      <PublicHeader/>
      <div class="page__wrapper">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import PublicHeader from '../components/PublicHeader'
import { mapActions } from 'vuex'
export default {
  name: 'PublicContainer',
    components: {
      PublicHeader
    },
    // created() {
    //   this.logout()
    // },
    mounted() {
    },
    methods: {
        ...mapActions('account', ['logout'])
    }
}
</script>